<template>
   <section class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>全渠道分销</el-breadcrumb-item>
            <el-breadcrumb-item v-if="action === 'edit'" :to="{ path: '/channel_manage/channel_list' }">渠道商列表</el-breadcrumb-item>
            <el-breadcrumb-item>{{ action === 'edit' ? '编辑' : '添加' }}渠道商</el-breadcrumb-item>
         </el-breadcrumb>
         <el-button class="bg-gradient" type="primary" plain @click="goBack">返回</el-button>
      </el-row>
      <el-row class="channel-box content-box">
         <el-row class="channel-cont">
            <el-row class="channel-item">
               <span><i>*</i>渠道商名称</span><el-input class="width-200" v-model="name" ></el-input>
            </el-row>
            <el-row class="channel-item">
               <span>等级</span>
               <el-row class="right-form">
                  <el-select class="m-left-10 width-200" v-model="level"  placeholder="请选择">
                     <el-option
                         v-for="item in levelList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
                     </el-option>
                  </el-select>
                  <p>选择渠道商级别，不同的级别在业务销售过程中，代理价格不同</p>
               </el-row>
            </el-row>
            <el-row class="channel-item">
               <span>类型</span>
               <el-row class="right-form">
                  <el-select class="m-left-10 width-200" v-model="type"  placeholder="请选择">
                     <el-option
                         v-for="item in typeList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
                     </el-option>
                  </el-select>
                  <p>根据营业执照类型选择对应的类型</p>
               </el-row>
            </el-row>
            <el-row class="channel-item">
               <span><i>*</i>联系人</span>
               <el-row class="right-form">
                  <el-input class="width-200" v-model="legalPerson" ></el-input>
                  <p class="m-left-10">填写法人姓名</p>
               </el-row>
            </el-row>
            <el-row class="channel-item">
               <span><i>*</i>联系电话</span>
               <el-row class="right-form">
                  <el-input class="width-200" v-model="mobile"  oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                  <p class="m-left-10">填写电话号码或手机号码</p>
               </el-row>
            </el-row>
            <el-row class="channel-item">
               <span>合作状态</span>
               <el-select class="m-left-10 width-200" v-model="status"  placeholder="请选择">
                  <el-option
                      v-for="item in cooperate"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
               </el-select>
            </el-row>
            <el-row class="channel-upload">
               <el-row>
                  <el-row v-if="echoUrl1" class="echo-img">
                     <img width="148" height="148" class="old-pic" :src="echoUrl1" alt="">
                     <span class="echo-bg"><i class="el-icon-delete" @click="removeImg(echoImgId1 , echoUrl1)"></i></span>
                  </el-row>
                  <el-row v-else>
                     <el-upload
                         ref="upload"
                         :limit="1"
                         :show-file-list="true"
                         action="#"
                         :auto-upload="false"
                         list-type="picture-card"
                         :on-change="handleChange"
                         :on-preview="handlePictureCardPreview">
                        <i class="el-icon-plus"></i>
                     </el-upload>
                     <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                     </el-dialog>
                     选择合作协议
                  </el-row>
               </el-row>
               <el-row class="flex">
                  <el-row v-if="echoUrl2" class="echo-img">
                     <img width="148" height="148" class="old-pic" :src="echoUrl2" alt="">
                     <span class="echo-bg"><i class="el-icon-delete" @click="removeImg(echoImgId2, echoUrl2)"></i></span>
                  </el-row>
                  <el-row v-else>
                     <el-upload
                         ref="upload"
                         :limit="1"
                         :show-file-list="true"
                         action="#"
                         :auto-upload="false"
                         list-type="picture-card"
                         :on-change="handleChange"
                         :on-preview="handlePictureCardPreview">
                        <i class="el-icon-plus"></i>
                     </el-upload>
                     <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                     </el-dialog>
                     选择营业执照
                  </el-row>
               </el-row>
            </el-row>
            <el-row class="channel-btn">
               <el-button  @click="handleReset" plain>取消</el-button>
               <el-button class="bg-gradient" type="primary" @click="handleSave">提交</el-button>
            </el-row>
         </el-row>
      </el-row>
   </section>
</template>

<script>
import {urlObj} from "@/api/interface";
import { distributor  } from "@/api/interface/business"
   import { mapState } from "vuex"
   export default {
      computed:{
         ...mapState(["hotelInfo"])
      },
      data(){
         return{
            hotelId: '',            // 酒店ID
            id: '',
            name: '',               // 渠道商名称
            level: 'ONE_LEVEL',     // 等级
            type: 'TRAVEL_AGENCY',  // 类型
            legalPerson: '',        // 联系人
            mobile: '',             // 联系电话
            status: '1',            // 合作状态
            attachments: [],        // 图片
            action: 'add',
            levelList: [
               { label: '一级',value: 'ONE_LEVEL' },
               { label: '二级',value: 'TWO_LEVEL' },
               { label: '三级',value: 'THREE_LEVEL' },
            ],
            typeList: [
               { label: '旅行社',value: 'TRAVEL_AGENCY' },
               { label: '企业员工',value: 'EMPLOYEE' },
               { label: '其他分销商',value: 'OTHER' },
            ],
            cooperate: [
               { label: '合作中',value: '1' },
               { label: '未合作',value: '0' },
            ],
            dialogVisible: false,
            dialogImageUrl: '',   // 放大图地址
            visible1: false,
            visible2: false,
            echoUrl1: '',
            echoUrl2: '',
            echoImgId1: '',
            echoImgId2: '',
            confirm: '确定'
         }
      },
      mounted() {
         this.hotelId = this.hotelInfo.id
         this.action = this.$route.query.action
         this.action === 'edit' && this.getDistributorInfo()
      },
      beforeDestroy() {
         sessionStorage.removeItem('distributorInfo')
      },
      methods: {
         // 获取编辑信息
         getDistributorInfo(){
            const distributorInfo = JSON.parse(sessionStorage.getItem('distributorInfo'))
            this.id = distributorInfo.id
            this.name = distributorInfo.name
            this.level = distributorInfo.level
            this.type = distributorInfo.type
            this.legalPerson = distributorInfo.legalPerson
            this.mobile = distributorInfo.mobile
            this.status = distributorInfo.status
            if (distributorInfo.attachment){
               this.attachments = JSON.parse(distributorInfo.attachment)
               if (this.attachments.length > 0) {
                  let imgObj1 = this.attachments[0]
                  this.echoUrl1 = window.getResourcesHost() + `/${imgObj1.location}` + `/${imgObj1.formatName}`
                  this.echoImgId1 = imgObj1.id
               }
               if (this.attachments.length > 1){
                  let imgObj2 = this.attachments[1]
                  this.echoUrl2 = window.getResourcesHost() + `/${imgObj2.location}` + `/${imgObj2.formatName}`
                  this.echoImgId2 = imgObj2.id
               }
            }
         },
         // 重置表单
         handleReset(){
            this.$router.push('/channel_manage/channel_list')
         },
         // 保存经销商
         handleSave(){
            // 表单验证
            if (!this.name) {
               this.$alert('渠道商名称不能为空！', '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.legalPerson) {
               this.$alert('联系人不能为空！', '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.mobile) {
               this.$alert('联系电话不能为空！', '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            const url = distributor.saveDistributor
            let param = {
               hotelId: this.hotelId,
               name: this.name,
               level: this.level,
               type: this.type,
               legalPerson: this.legalPerson,
               mobile: this.mobile,
               status: this.status,
               attachments: this.attachments
            }
            if (this.action === 'edit') {
               param.id = this.id
               if (this.attachments.length === 0) {
                  param.attachment = ''
               }
            }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: '保存成功！',
                     type: 'success'
                  })
                  this.goBack()
               }
            })
         },
         // 点击图片查看大图
         handlePictureCardPreview(file){
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
         },
         // 获取上传的文件资源
         handleChange(file){
            // 验证文件格式、大小
            const imgFormat = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!imgFormat) {
               this.$message.error('上传LOGO图片只能是JPG/PNG/GIF格式!');
               // 强制刷新局部DOM节点
               /*this.isRefresh = false
               this.$nextTick(() => {
                  this.isRefresh = true
               })
               return*/
            }
            if (!isLt2M) {
               this.$message.error('上传LOGO图片大小不能超过 2MB!');
               /*this.isRefresh = false
               this.$nextTick(() => {
                  this.isRefresh = true
               })
               return*/
            }
            const url = urlObj.uploadDetailFile
            let param = new FormData()
            param.append('file', file.raw)
            param.append('bizId', 'default')
            param.append('module', 'event')
            param.append('menu', 'distributor')
            param.append('func', 'image')
            param.append('category', 'image')
            param.append('isThumb', true)
            this.$axios.post(url, param, 'file').then(res => {
               if (res.success) {
                  this.attachments.push(res.records)
                  this.$message({ showClose: true, message: '上传成功！', type: 'success'})
               }
            })
         },
         // 移除图片
         removeImg(id, echoUrl){
            this.attachments = this.attachments.filter(item => {
               return item.id !== id
            })
            if (this.echoUrl1 === echoUrl) this.echoUrl1 = ''
            if (this.echoUrl2 === echoUrl) this.echoUrl2 = ''
         },
         // 返回上一页
         goBack(){
            this.$router.push('/channel_manage/channel_list')
         }
      }
   }
</script>

<style lang="scss" scoped>
.cont{min-width: 1000px !important;}
   .channel-box{

      .channel-cont{
         padding: 30px 0 0 120px; padding-bottom: 30px;
         background: #fff;
         .channel-item{
            overflow: hidden; line-height: 28px; margin-bottom: 20px;
            span{
               float: left; width: 100px; text-align: right;
               i { font-size: 18px; color: red; margin-right: 5px }
            }
            .el-input{ float: left; margin-left: 10px }
            .right-form{
               p{ display: inline; font-size: 12px; color: #999999 }
            }
         }
         .channel-upload{
            display: flex;
            div{ margin: 0 10px; text-align: center }
            .flex{ display: flex }
            .echo-img{ position: relative; border: 1px #eeeeee solid; border-radius: 6px }
            .echo-bg{
               position: absolute; width: 100%; height: 100%; background: rgba(0, 0 ,0 , 0.4);
               left: 0; top: 0;
            }
            .el-icon-delete{
               font-size: 24px; color: #FFFFFF; line-height: 148px;
            }
         }
         .channel-btn{ margin: 30px 0 0 10px }
      }
   }
</style>
